import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Toolbar,
  Typography,
  Grid,
  Badge,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import {
  Logout,
  Notifications,
  Person,
  Settings,
} from "@mui/icons-material";
import * as authService from "../../services/api/auth"
import { userData } from "../../atoms/userState";
import { useRecoilState, useSetRecoilState } from "recoil";
import { API_ROUTE } from "../../constants";


const DashboardHeader = ({ drawerWidth, handleDrawerToggle }) => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [loggedUser, setUserData] = useRecoilState(userData);
  const userMenuOpen = Boolean(userMenuAnchorEl);
  const handleOpenUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const closeUserMenu = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = ()=>{
    authService.logOut();
    setUserData(null);
  }
  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        background: "#FFFFFF",
        boxShadow:"0 0px 20px 0px rgba(0, 0, 0, 0.05);",
        borderRadius: 0
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Grid>
          <Grid item>
            <IconButton aria-label="" edge="end" sx={{ mr: 1 }}>
              <Badge aria-label="messages" badgeContent={6} color="error">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="" edge="end" sx={{ mr: 1 }}>
              <Badge aria-label="notifications" badgeContent={4} color="error">
                <Notifications />
              </Badge>
            </IconButton>
            <React.Fragment>
              <IconButton
                aria-label=""
                color="primary"
                edge="end"
                sx={{ mr: 1 }}
                onClick={handleOpenUserMenu}
              >
                <Avatar src={`${API_ROUTE}/asset/${loggedUser?.user?.imageUrl}`} sx={{ height: 32, width: 32 }}>
                  <Person />
                </Avatar>
              </IconButton>
              <Menu
                id="profileMenu"
                anchorEl={userMenuAnchorEl}
                keepMounted
                open={userMenuOpen}
                onClose={closeUserMenu}
                PaperProps={{
                  elevation: 1,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0 0px 20px 0px rgba(0, 0, 0, 0.05))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      mr: 1,
                      height: 32,
                      width: 32,
                    },
                  },
                }}
              >
                <MenuItem component={Link} to="/profile">
                  <Avatar /> Mon Compte
                </MenuItem>
                <Divider />
                <MenuItem component={Link} to="/settings">
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Paramètres
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Déconnexion
                </MenuItem>
              </Menu>
            </React.Fragment>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          color="InfoText"
          noWrap
          component="div"
        ></Typography>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
