import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { DashBoardLayout } from "../../components/Dashboard/DashBoardLayout";
import PostsListPage from "./PostsListPage";

const PostsPage = () => {
  return (
    <DashBoardLayout>
     <Routes>
       <Route index element={<PostsListPage/>}/>
     </Routes>
    </DashBoardLayout>
  );
};

export default PostsPage;
