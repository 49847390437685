import { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  MoreVertRounded,
} from "@mui/icons-material";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import * as NewsService from "../../services/api/news";

const NewsListPage = () => {
  const [news, setNews] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteNewsDialog, setOpenDeleteNewsDialog] = useState(false);
  const [requestMessage, setRequestMessage] = useState(null);

  const openTableRowActionMenu = Boolean(anchorEl);

  let navigate = useNavigate();

  const handleRowActionMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedNews(event.currentTarget.id);
  };
  const handleTableRowMenuClose = () => {
    setAnchorEl(null);
    //setSelectedNews(null);
  };
  const handleDeleteNewsDialogOpenClick = () => {
    setOpenDeleteNewsDialog(true);
  };

  const navigateToEditNews = () => {
    navigate(`edit/${selectedNews}`);
  };

  const handleConfirmDeleteNewsClick = () => {
    console.log(selectedNews);
    NewsService.remove(selectedNews)
      .then(
        (response) => {
          setRequestMessage({
            type: "success",
            message: "News supprimé avec success",
          });
          let filteredNews = news.filter((item) => item._id !== selectedNews);
          setNews([...filteredNews]);
        },
        (reason) => {
          setRequestMessage({
            type: "error",
            message: "Echec de la suppression du news",
          });
        }
      )
      .finally(() => {
        setOpenDeleteNewsDialog(false);
      });
  };

  const fetchNews = () => {
    NewsService.findAll().then(
      (response) => {
        setNews(response.data);
      },
      (reason) => {
        console.log(reason?.response?.data);
      }
    );
  };

  useEffect(() => {
    fetchNews();
    return () => {};
  }, []);
  return (
    <Grid container sx={{ maxHeight: "100vh" }}>
      <Grid item sx={{ width: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} underline="hover" color="inherit" to="/">
            Dashboard
          </Link>
          <Link component={RouterLink} underline="hover" color="inherit" to="/news">
            News
          </Link>
        </Breadcrumbs>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <Button
            onClick={() => navigate("create")}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Nouveau
          </Button>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="Liste de News" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Author</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {news?.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{`${row.user.firstName} ${row.user.lastName}`}</TableCell>
                    <TableCell>{row.commentsCount}</TableCell>
                    <TableCell>
                      {dayjs(row.createdAt).format("DD-MM-YYYY à H:mm")}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="news action"
                        id={row._id}
                        onClick={handleRowActionMenuClick}
                      >
                        <MoreVertRounded />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openTableRowActionMenu}
            onClose={handleTableRowMenuClose}
            onClick={handleTableRowMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={navigateToEditNews}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              Modifier
            </MenuItem>
            <MenuItem onClick={handleDeleteNewsDialogOpenClick}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Supprimer
            </MenuItem>
          </Menu>

          <Dialog
            open={openDeleteNewsDialog}
            onClose={() => setOpenDeleteNewsDialog(false)}
          >
            <DialogTitle>Suppression du news</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-Vous supprimer ce news?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDeleteNewsDialog(false)}
                color="primary"
              >
                Annuler
              </Button>
              <Button onClick={handleConfirmDeleteNewsClick} color="warning">
                Oui
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={requestMessage ? true : false}
            autoHideDuration={6000}
            onClose={() => setRequestMessage(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setRequestMessage(null)}
              severity={requestMessage?.type || "error"}
              sx={{ width: "100%" }}
            >
              {requestMessage?.message}
            </Alert>
          </Snackbar>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NewsListPage;
