import React, { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import DrawerMenu from "../../components/Dashboard/DrawerMenu";
import DashboardHeader from "../../components/Dashboard/DashboardHeader";

const drawerWidth = 240;

export const DashBoardLayout = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex", backgroundColor: "#f2f8ff", minHeight: "100vh" }}>
      <DashboardHeader
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
      />
      <DrawerMenu
        drawerWidth={drawerWidth}
        container={container}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`,xs: "100%" },
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
};
