import { Alert, AlertTitle } from "@mui/material";

const AlertMessage = ({ messageInfos }) => {
  return (
    <Alert severity={messageInfos.type} sx={{ boxShadow: "none" }}>
      <AlertTitle>{messageInfos.title}</AlertTitle>
      {messageInfos.message}
    </Alert>
  );
};

export default AlertMessage;
