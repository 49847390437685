import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { DashBoardLayout } from "../../components/Dashboard/DashBoardLayout";

const MessagesPage = () => {
  return (
    <DashBoardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" href="/messages">
          Messages
        </Link>
      </Breadcrumbs>
    </DashBoardLayout>
  );
};

export default MessagesPage;
