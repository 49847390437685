import { api } from "../api/api";
export const findAll = () => {
  return api.get("/news");
};

export const findById = (newsId) => {
  return api.get(`/news/${newsId}`);
};

export const update = (newsData) => {
  return api.post("/news/update", newsData);
};

export const create = (newsData) => {
  return api.post("/news", newsData);
};

export const remove = (newsId) => {
  return api.delete(`/news/${newsId}`);
};
