import React from "react";
import { Container, Typography, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
}));

const PrivacyPolicyPage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Paper className={classes.container} elevation={3}>
        <Typography variant="h4" gutterBottom>
          Politique de Confidentialité - Asq'me
        </Typography>

        <Typography paragraph>
          Nous vous remercions d'utiliser Asq'me, le réseau social éducatif
          dédié à favoriser l'entraide entre élèves. Veuillez lire attentivement
          notre politique de confidentialité pour comprendre comment nous
          collectons, utilisons et protégeons vos informations.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Collecte d'informations
        </Typography>
        <Typography paragraph>
          Nous collectons des informations personnelles lorsque vous utilisez
          notre application, notamment lorsque vous créez un compte, participez
          à des discussions, accédez à des cours, ou soumettez des épreuves
          corrigées. Les informations collectées peuvent inclure votre nom,
          adresse e-mail, établissement scolaire, et les contenus que vous
          partagez.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Utilisation des informations
        </Typography>
        <Typography paragraph>
          Nous utilisons vos informations pour faciliter l'entraide entre
          élèves, personnaliser votre expérience sur l'application, fournir des
          cours et épreuves corrigées, et améliorer nos services. Vos
          informations ne seront jamais vendues ou partagées avec des tiers à
          des fins publicitaires.
        </Typography>

        {/* Ajoutez d'autres sections selon vos besoins */}

        <Typography variant="h5" gutterBottom>
          Contactez-nous
        </Typography>
        <Typography paragraph>
          Si vous avez des questions concernant notre politique de
          confidentialité, veuillez nous contacter à l'adresse suivante :
          contact@asqme.com
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicyPage;
