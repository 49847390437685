import { useState, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  MoreVertRounded,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import * as PostService from "../../services/api/post";
import { API_ROUTE } from "../../constants";

const PostsListPage = () => {
  const [posts, setPosts] = useState([]);
  const [slectedPostId, setSelectedPostId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeletePostDialog, setOpenDeletePostDialog] = useState(false);
  const [requestMessage, setRequestMessage] = useState(null);

  const openTableRowActionMenu = Boolean(anchorEl);

  let navigate = useNavigate();

  const handleRowActionMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedPostId(event.currentTarget.id);
  };
  const handleTableRowMenuClose = () => {
    setAnchorEl(null);
    //setSelectedPostId(null);
  };
  const handleDeletePostDialogOpenClick = () => {
    setOpenDeletePostDialog(true);
  };

  const navigateToEditPost = () => {
    navigate(`edit/${slectedPostId}`);
  };

  const handleConfirmDeletePostClick = () => {
    console.log(slectedPostId);
    PostService.remove(slectedPostId)
      .then(
        (response) => {
          setRequestMessage({
            type: "success",
            message: "Post supprimé avec success",
          });
          let filteredPost = posts.filter((item) => item._id !== slectedPostId);
          setPosts([...filteredPost]);
        },
        (reason) => {
          setRequestMessage({
            type: "error",
            message: "Echec de la suppression du posts",
          });
        }
      )
      .finally(() => {
        setOpenDeletePostDialog(false);
      });
  };

  const fetchPost = () => {
    PostService.findAll().then(
      (response) => {
        setPosts(response.data);
      },
      (reason) => {
        console.log(reason?.response?.data);
      }
    );
  };

  useEffect(() => {
    fetchPost();
    return () => {};
  }, []);
  return (
    <Grid container sx={{ maxHeight: "100vh" }}>
      <Grid item sx={{ width: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="/posts">
            Posts
          </Link>
        </Breadcrumbs>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <Button
            onClick={() => navigate("create")}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            Nouveau
          </Button>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="Liste de Post" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Auteur</TableCell>
                  <TableCell>Classe</TableCell>
                  <TableCell>cours</TableCell>
                  <TableCell>Comments</TableCell>
                  <TableCell>content</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts?.map((post, index) => {
                  return (
                    <TableRow key={post._id}>
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          alignContent={"center"}
                        >
                          <Avatar
                            src={`${API_ROUTE}/asset/${post.user.imageUrl}`}
                          />
                          <Typography
                            sx={{ ml: 2, fontSize: "0.875rem" }}
                            variant="h6"
                            color="GrayText"
                          >
                            {`${post.user.firstName} ${post.user.lastName}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {`${post.classe}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {post.cours}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {post.commentsCount}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                          noWrap
                        >
                          {`${post.content.substr(0, 20)} [...]`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {dayjs(post.createdAt).format("DD-MM-YYYY à H:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="news action" id={post._id}>
                          <MoreVertRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openTableRowActionMenu}
            onClose={handleTableRowMenuClose}
            onClick={handleTableRowMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={navigateToEditPost}>
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              Modifier
            </MenuItem>
            <MenuItem onClick={handleDeletePostDialogOpenClick}>
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Supprimer
            </MenuItem>
          </Menu>

          <Dialog
            open={openDeletePostDialog}
            onClose={() => setOpenDeletePostDialog(false)}
          >
            <DialogTitle>Suppression du posts</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-Vous supprimer ce posts?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenDeletePostDialog(false)}
                color="primary"
              >
                Annuler
              </Button>
              <Button onClick={handleConfirmDeletePostClick} color="warning">
                Oui
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={requestMessage ? true : false}
            autoHideDuration={6000}
            onClose={() => setRequestMessage(null)}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={() => setRequestMessage(null)}
              severity={requestMessage?.type || "error"}
              sx={{ width: "100%" }}
            >
              {requestMessage?.message}
            </Alert>
          </Snackbar>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PostsListPage;
