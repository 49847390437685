import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  Alert,
  Avatar,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import * as authService from "../../services/api/auth";
import { userData } from "../../atoms/userState";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [requestError, setRequestError] = useState(null);
  const setUserData = useSetRecoilState(userData);

  const handleLogin = (event) => {
    event.preventDefault();
    try {
      authService
      .login({ email: email, password: password })
      .then(
        (response) => {
          const data = response.data;
          if (
            data?.user?.roles?.includes("ROOT") ||
            data?.user?.roles?.includes("ADMIN") ||
            data?.user?.roles?.includes("MODERATOR")
          ) {
            setRequestError(null);
            authService.saveUserDataFromSession(data);
            setUserData(data);
          } else {
            setRequestError(
              "Accès refusé. Veuillez contacter l'administrateur."
            );
          }
        },
        (reason) => {
          setRequestError(reason?.response?.data);
        }
      )
      .catch((reason) => {
        setRequestError(reason);
      });
    } catch (error) {
      console.log(error)
    }
  };
  return (
    <Container component="main" maxWidth="xs" sx={{ maxHeight: "100vh" }}>
      <Box
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          minHeight: "90vh",
        }}
      >
        <Avatar sx={{ bgcolor: "secondary.main", m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" noValidate>
          {requestError && (
            <Alert sx={{ mt: 1, mb: 1 }} severity="error">
              {requestError}
            </Alert>
          )}
          <TextField
            id="email"
            required
            autoFocus
            label="Email"
            name="email"
            autoComplete="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            id="password"
            required
            autoFocus
            label="password"
            name="password"
            type="password"
            fullWidth
            autoComplete="current-password"
            margin="normal"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ mt: 2, mb: 1 }}
            onClick={handleLogin}
          >
            Se Connecter
          </Button>
        </Box>
      </Box>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="">
          Asqme
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Container>
  );
};

export default LoginPage;
