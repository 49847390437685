import React from "react";
import { Routes, Route } from "react-router-dom";
import { DashBoardLayout } from "../../components/Dashboard/DashBoardLayout";
import CreateNewsPage from "./CreateNewsPage";
import NewsListPage from "./NewsListPage";

const NewsPage = () => {
  return (
    <DashBoardLayout>
      <Routes>
        <Route index element={<NewsListPage />} />
        <Route path="list" element={<NewsListPage />} />
        <Route path="create" element={<CreateNewsPage />} />
        <Route path="edit/:newsId" element={<CreateNewsPage />} />
      </Routes>
    </DashBoardLayout>
  );
};

export default NewsPage;
