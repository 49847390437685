import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { DashBoardLayout } from "../../components/Dashboard/DashBoardLayout";
import UsersList from "./UsersList";

const UsersPage = () => {
  return (
    <DashBoardLayout>
     <Routes>
       <Route index element={<UsersList/>}/>
     </Routes>
    </DashBoardLayout>
  );
};

export default UsersPage;
