import { Breadcrumbs, Link } from "@mui/material";
import React from "react";
import { DashBoardLayout } from "../../components/Dashboard/DashBoardLayout";

const ProfilePage = () => {
  return (
    <DashBoardLayout>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Dashboard
        </Link>
        <Link underline="hover" color="inherit" href="/profile">
          Mon profile
        </Link>
      </Breadcrumbs>
    </DashBoardLayout>
  );
};

export default ProfilePage;
