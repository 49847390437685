import { useState, useRef, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  TextField,
  Stack,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Save, Upload, Delete, AttachFile } from "@mui/icons-material";
import { substrFileName } from "../../helpers/common";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import * as newsService from "../../services/api/news";
import AlertMessage from "../../components/AlertMessage";

const CreateNewsPage = () => {
  const [newsTitle, setNewsTitle] = useState("");
  const [newsContent, setNewsContent] = useState("");
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [news, setNews] = useState(null);
  const [requestMessage, setRequestMessage] = useState(null);
  const fileInputRef = useRef(null);

  let { newsId } = useParams();
  let navigate = useNavigate();

  const handleUploadButtonClick = () => {
    fileInputRef?.current?.click();
  };

  useEffect(() => {
    setRequestMessage(null);
    return () => {};
  }, []);
  useEffect(() => {
    if (newsId !== undefined) {
      newsService.findById(newsId).then(
        (response) => {
          let news = response.data;
          setNews(news);
          setNewsTitle(news?.title);
          setNewsContent(news?.content);
          setUploadedFiles([...news?.medias]);
        },
        (reason) => {
          console.log(reason?.response?.data);
        }
      );
    }
    return () => {};
  }, [newsId]);

  const handleInputFileChange = (event) => {
    const file = event.target.files[0];
    setFilesToUpload([
      ...filesToUpload,
      { id: new Date().toString(), file: file },
    ]);
    console.log(filesToUpload);
  };
  const handleDeleteFileToUploadList = (fileId) => {
    setFilesToUpload(filesToUpload.filter((file) => file.id !== fileId));
  };

  const handleSaveNewsClick = () => {
    const formData = new FormData();
    filesToUpload?.forEach((file) => {
      formData.append("medias", file.file);
    });
    formData.append("title", newsTitle);
    formData.append("content", newsContent);

    if (newsTitle.length === 0) {
      setRequestMessage({
        type: "warning",
        title: "Validation",
        message: "Veuillez saisir un titre.",
      });
      return;
    }

    if (newsContent.length === 0) {
      setRequestMessage({
        type: "warning",
        title: "Validation",
        message: "Veuillez saisir un contenu.",
      });
      return;
    }

    if (news) {
      formData.append("newsId", news._id);
      updateNews(formData);
    } else {
      createNews(formData);
    }
  };

  const createNews = (newsData) => {
    newsService.create(newsData).then(
      (response) => {
        navigate("/news");
      },
      (reason) => {
        setRequestMessage({
          type: "error",
          message: reason?.response?.data || "Erreur lors de création du News",
        });
      }
    );
  };
  const updateNews = (newsData) => {
    newsService.update(newsData).then(
      (response) => {
        setRequestMessage({
          type: "success",
          message: "News mis à jour avec succès",
        });
      },
      (reason) => {
        setRequestMessage({
          type: "error",
          message: reason?.response?.data || "News mis à jour avec succès",
        });
      }
    );
  };
  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} underline="hover" color="inherit" to="/">
          Dashboard
        </Link>
        <Link component={RouterLink} underline="hover" color="inherit" to="/news">
          News
        </Link>
        <Typography as="h6">{newsId ? "Edition" : "Nouveau"}</Typography>
      </Breadcrumbs>
      <Grid
        container
        spacing={2}
        sx={{
          boxShadow:
            "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px; ",
          mt: 2,
          borderRadius: 2,
          p: 2,
          background: "#FFFFFF"
        }}
      >
        <Grid item xs={12} md={4} >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
          >
            <Typography as="h6" color="grey">
              Fichiers
            </Typography>
            <IconButton aria-label="Upload" onClick={handleUploadButtonClick}>
              <Upload />
            </IconButton>
          </Stack>
          <List>
            {filesToUpload?.map((file) => {
              return (
                <ListItem
                  key={file.id}
                  sx={{ pl: 0 }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteFileToUploadList(file.id)}
                    >
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ height: 32, width: 32 }}>
                      <AttachFile />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ fontStyle: "italic" }}
                    primary={substrFileName(file?.file?.name)}
                  />
                </ListItem>
              );
            })}
            {uploadedFiles?.map((file, index) => {
              return (
                <ListItem
                  key={index.toString()}
                  sx={{ pl: 0 }}
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <Delete />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ height: 32, width: 32 }}>
                      <AttachFile />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{ fontStyle: "italic" }}
                    primary={substrFileName(file)}
                  />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={12} md={8} >
          {requestMessage && <AlertMessage messageInfos={requestMessage} />}
          <Box component="form" noValidate>
            <input
              type="file"
              ref={fileInputRef}
              hidden
              name="file"
              id="file"
              accept=".png,.pdf,.docx,.doc,.jpg,.jpeg"
              onChange={handleInputFileChange}
            />
            <TextField
              id="news-title"
              label="Titre"
              value={newsTitle}
              onChange={(event) => setNewsTitle(event.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              id="news-content"
              label="Contenu"
              variant="outlined"
              color="primary"
              margin="normal"
              value={newsContent}
              onChange={(event) => setNewsContent(event.target.value)}
              fullWidth
              multiline
              rows={8}
            />
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={0.5}
            >
              <Button
                onClick={handleSaveNewsClick}
                variant="contained"
                color="primary"
                startIcon={<Save />}
              >
                Enregistrer
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateNewsPage;
