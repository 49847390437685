import axios from "axios";
import { API_ROUTE } from "../../constants";
import { getUserDataFromSession } from "./auth";

export const api = axios.create({
  baseURL: `${API_ROUTE}`,
});

api.interceptors.request.use((config) => {
  let user = getUserDataFromSession()
  config.headers["x-access-token"] = user?.accessToken;
  return config;
});
