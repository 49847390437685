import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  Newspaper,
  Notifications,
  PeopleAltOutlined,
  Person,
  PostAdd,
  Settings,
} from "@mui/icons-material";
import Mail from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
const DrawerContent = () => {
  return (
    <div>
      <div>
        <Toolbar
          sx={{
            textAlign: "center",
            transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            boxShadow: "0 0px 20px 0px rgba(0, 0, 0, 0.05);",
            backgroundColor: "#FFFFFF"
          }}
        >
          <Typography variant="h6">ASQME APP</Typography>
        </Toolbar>
        <List>
          <ListItem button component={Link} to="/dashboard" >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard"  />
          </ListItem>
          <ListItem button component={Link} to="/posts">
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="Posts" />
          </ListItem>
          <ListItem button component={Link} to="/news">
            <ListItemIcon>
              <Newspaper />
            </ListItemIcon>
            <ListItemText primary="News" />
          </ListItem>
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <PeopleAltOutlined />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button component={Link} to="/profile">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Mon Compte" />
          </ListItem>
          <ListItem button component={Link} to="/notifications">
            <ListItemIcon>
              <Notifications />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItem>
          <ListItem button component={Link} to="/messages">
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText primary="Messages" />
          </ListItem>
          <ListItem button component={Link} to="/settings">
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Paramètres" />
          </ListItem>
        </List>
      </div>
    </div>
  );
};

export default DrawerContent;
