import { USER_DATA_SESSION_STORAGE } from "../../constants";
import { api } from "./api";

export const login = ({ email, password }) => {
  return api.post("auth/login",{email,password});
};

export const logOut = () => {
  removeUserDataFromSession();
};

export const getUserDataFromSession = () => {
  try {
    const userData = sessionStorage.getItem(USER_DATA_SESSION_STORAGE);
    return userData?.length === 0 ? null : JSON.parse(userData);
  } catch (error) {
    return null;
  }
};

export const saveUserDataFromSession = (userData = null) => {
  sessionStorage.setItem(
    USER_DATA_SESSION_STORAGE,
    userData ? JSON.stringify(userData) : ""
  );
};

export const removeUserDataFromSession = () => {
  sessionStorage.removeItem(USER_DATA_SESSION_STORAGE);
};
