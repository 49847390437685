import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RecoilRoot } from "recoil";
import { CssBaseline } from "@mui/material";

const theme = createTheme({
  
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundCcolor: "rgb(255, 255, 255)",
          color: "rgb(33, 43, 54)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundImage: "none",
          overflow: "hidden",
          boxShadow:
            " rgba(145, 158, 171, 0.24) 0px 0px 2px 0px,rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
          borderRadius: "10px",
        },
      },
    },
  },
});
ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
          <CssBaseline />
        </ThemeProvider>
      </React.StrictMode>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
