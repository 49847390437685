import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DrawerContent from "./DrawerContent";

const DrawerMenu = ({
  drawerWidth,
  container,
  handleDrawerToggle,
  mobileOpen,
}) => {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: 0,
          },
          "& .MuiSvgIcon": {
            color: "#7b8eb4",
          },
        }}
      >
        <DrawerContent />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: 0,
          },
          "& .MuiSvgIcon-root": {
            color: "#7b8eb4",
          },
          "& .MuiTypography-root": {
            color: "#7f889b",
          },
          "& .MuiButtonBase-root": {
            "&:hover": {
              backgroundColor: "#425ebd",
              "& .MuiSvgIcon-root": {
                color: "#FFFF",
              },
              "& .MuiTypography-root": {
                color: "#FFFF",
              },
            },
          },
        }}
        open
      >
        <DrawerContent />
      </Drawer>
    </Box>
  );
};

export default DrawerMenu;
