import { useEffect, Fragment } from "react";
import { useRecoilState } from "recoil";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import LoginPage from "./pages/Login";
import MessagesPage from "./pages/Messages/MessagesPage";
import NewsPage from "./pages/News/NewsPages";
import NotificationsPage from "./pages/Notifications/NotificationsPage";
import PostsPage from "./pages/Posts/PostsPage";
import ProfilePage from "./pages/Profile/ProfilePage";
import SettingsPage from "./pages/Settings/SettingsPage";
import UsersPage from "./pages/Users/UsersPage";
import { userData } from "../src/atoms/userState";
import { getUserDataFromSession } from "./services/api/auth";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicy";
function App() {
  const [userState, setUserState] = useRecoilState(userData);

  useEffect(() => {
    setUserState(getUserDataFromSession());
    return () => {};
  }, []);
  return (
    <Routes>
      {!userState && <Route path="/login" element={<LoginPage />} />}
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      {userState && (
        <Fragment>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/posts/*" element={<PostsPage />} />
          <Route path="/news/*" element={<NewsPage />}/>
          <Route path="/users/*" element={<UsersPage />} />
          <Route path="/messages" element={<MessagesPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Fragment>
      )}
      <Route
        path="*"
        element={<Navigate to={userState ? "/dashboard" : "/login"} />}
      />
      
    </Routes>
  );
}

export default App;
