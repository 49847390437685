/**
 * @argument String
 * @returns String
 * @description return shorten filename
 **/
export const substrFileName = (fileName) => {
  const stringLength = 18;
  const fileExtension = fileName.split(".").pop();
  const newFileName = fileName.split(".")[0].substr(0, 18);
  return fileName?.length > stringLength
    ? `${newFileName}...${fileExtension}`
    : fileName;
};
