import { useEffect, useState } from "react";
import {
  Grid,
  Breadcrumbs,
  Link,
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  Stack,
  Typography,
  Chip,
} from "@mui/material";
import { Add, MoreVertRounded } from "@mui/icons-material";
import * as UserService from "../../services/api/user";
import { Avatar } from "@material-ui/core";
import dayjs from "dayjs";
import { API_ROUTE } from "../../constants";

function UsersList() {
  const [users, setUsers] = useState([]);

  const fetchUsers = () => {
    UserService.findAll().then(
      (response) => {
        console.log(response.data);
        setUsers(response.data);
      },
      (reason) => {
        console.log(reason?.response?.data);
      }
    );
  };

  useEffect(() => {
    fetchUsers();
    return () => {};
  }, []);
  return (
    <Grid container sx={{ maxHeight: "100vh" }}>
      <Grid item sx={{ width: "100%" }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="/news">
            Utilisateurs
          </Link>
        </Breadcrumbs>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "end",
          }}
        >
          <Button variant="contained" color="primary" startIcon={<Add />}>
            Ajouter
          </Button>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="Liste des utilisateurs" sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Classe</TableCell>
                  <TableCell>verified</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Last login</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((user, index) => {
                  return (
                    <TableRow key={user._id}>
                      <TableCell>
                        <Stack
                          direction="row"
                          alignItems={"center"}
                          alignContent={"center"}
                        >
                          <Avatar src={`${API_ROUTE}/asset/${user.imageUrl}`} />
                          <Typography
                            sx={{ ml: 2, fontSize: "0.875rem" }}
                            variant="h6"
                            color="GrayText"
                          >
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ ml: 2, fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {user.roles.join(" , ")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ ml: 2, fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {user.classe?.shortName || "-/-"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ ml: 2, fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          <Chip
                            label={user.verified ? "OUI" : "NON"}
                            size="small"
                            color={user.verified ? "success" : "warning"}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ ml: 2, fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          <Chip
                            label={user.isActive ? "Active" : "Banned"}
                            size="small"
                            color={user.isActive ? "success" : "error"}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ ml: 2, fontSize: "0.875rem" }}
                          variant="h6"
                          color="GrayText"
                        >
                          {dayjs(user.lastLogin).format("DD-MM-YYYY à H:mm")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="news action" id={user._id}>
                          <MoreVertRounded />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UsersList;
